<template>
  <div>
    <b-table
      selectable
      :items="publications"
      :fields="fields"
      responsive="sm"
      @row-selected="onRowSelected"
    />

  </div>
</template>
 
<script>

import { BTable } from 'bootstrap-vue'
import publicationApi from '@/dl_pubmill/apis/publication'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      userRoles: null,
      publications: [],
      previousMenuHidden: null,
      fields: [
        'name',
        'publisher'
      ],
    }
  },
  watch: {
    '$store.state.dlStore.userRoles': {
      // watch on route changes it not only needed when same .vue is enteredfrom multiple nav points
      handler() {
        this.userRoles = this.$store.state.dlStore.userRoles
        console.log('[**MyPublications**.watch], userRoles', this.userRoles)
      },
    },
  },
  created() {
    this.previousMenuHidden = this.$store.state.appConfig.layout.menu.hidden
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.previousMenuHidden)
  },
  mounted() {
    if (this.$store.state.pubmillGlobalStore.currentPublicationObject) {
      this.$store.commit('pubmillGlobalStore/updateCurrentPublicationName', null)
    }
    this.getMyPublications()
    this.isMounted = true
    this.userRoles = this.$store.state.dlStore.userRoles
    console.log('[**MyPublications**.mounted], userRoles', this.userRoles)
  },
  methods: {
    onRowSelected(items) {
      this.goToPublication(items[0].issn)
    },
    goToPublication(issn) {
      // doing this breaks the left side menu when revisigin mypublications and the 
      // drilling back to production-admin
      //this.$router.push({ name: 'production-admin', params: { issn } })

      const url = '/production-admin/'.concat(issn)
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      const dest =  prot.concat(host, url)
      window.location.href = dest
    },

    getMyPublications () {
      publicationApi.getMyPublications(this, null)
    },
    backFromGetMyPublications (serverData) {
      //console.log('backFromGetMyPublications', serverData)
      this.publications = []
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        this.publications = serverData.administered_publications
      }
    }
  },
}
</script>
